import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Fade, Modal, Row, Table } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faCopy, faMagic, faRobot } from '@fortawesome/free-solid-svg-icons';
import APIClient from '../../services/APIClient';
import {
  EntityEditForm,
  FormInputField,
  FormSelectField,
  Loading,
  ConversationContainer,
} from '../../components';
import FormSelectCustom from '../../components/FormSelectCustom';
import OrderItemTableRow from '../../components/OrderItemTableRow';
import moment from 'moment';
import { StatusCode } from '../../constants';
import { withRouter } from '../withRouter';
import UIUtils from '../UIUtils';
import { renderSourceChannel, generateDeliveryString, formatAmount } from '../Utils';
import {
  AIExecution,
  Agent,
  Conversation,
  DeliveryPoint,
  InterpretOrderResult,
  OrderItem,
  PaymentMethod,
  PriceList,
  PriceListDetail,
  PriceListResume,
  Product,
} from '../../types/model';
import { AxiosError, AxiosResponse } from 'axios';
import './styles.css';

function OrderAIEdit({ navigate, params, toastManager }) {
  const [agents, setAgents] = useState<Agent[]>([]);
  const [aiExecution, setAiExecution] = useState<AIExecution | null>(null);
  const [aiExecutionResultOrder, setAiExecutionResultOrder] = useState<InterpretOrderResult | null>(
    null,
  );
  const [client, setClient] = useState<any>(null);
  const [conversation, setConversation] = useState<Conversation>();
  const [entity, setEntity] = useState<any>({});
  const [deliveryDate, setDeliveryDate] = useState<string>();
  const [deliveryPoints, setDeliveryPoints] = useState<DeliveryPoint[]>([]);
  const [deliveryPointId, setDeliveryPointId] = useState<number | null>(null);
  const [id, setId] = useState<number>(parseInt(params.id));
  const [isAdding, setIsAdding] = useState<boolean>(typeof id === 'undefined');
  const [isAiDataVisible, setisAiDataVisible] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isItemsModified, setIsItemsModified] = useState<boolean>(false);
  const [showPurgeModal, setShowPurgeModal] = useState<boolean>(false);
  const [isLoadingCopyText, setIsLoadingCopyText] = useState<boolean>(false);
  const [itemsToDelete, setItemsToDelete] = useState<number[]>([]);
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [priceListDetails, setPriceListDetails] = useState<PriceListDetail[]>([]);
  const [priceLists, setPriceLists] = useState<PriceListResume[]>([]);
  const [selectedPriceListId, setSelectedPriceListId] = useState<number | null>(null);
  const [showAiMessageModal, setShowAiMessageModal] = useState<boolean>(false);
  const [totalAmount, setTotalAmount] = useState<number>(0);

  const [clientAiIconColor, setClientAiIconColor] = useState<string | null>();
  const [clientAiIconText, setClientAiIconText] = useState<string | null>();
  const [deliveryDateAiIconColor, setDeliveryDateAiIconColor] = useState<string | null>();
  const [deliveryDateAiIconText, setDeliveryDateAiIconText] = useState<string | null>();
  const [deliveryPointAiIconColor, setDeliveryPointAiIconColor] = useState<string | null>();
  const [deliveryPointAiIconText, setDeliveryPointAiIconText] = useState<string | null>();

  useEffect(() => {
    if (!isEditable) {
      // if not editable, use prices in items
      return;
    }
    // update prices with the selected price list
    const localOrderItems = updatePrices(orderItems);
    setOrderItems(localOrderItems);
  }, [priceListDetails]);

  useEffect(() => {
    updateTotal();
  }, [orderItems]);

  useLayoutEffect(() => {
    computeAiInfo();
  }, [client, deliveryDate, deliveryPointId]);

  const updatePrices = (items: OrderItem[]): OrderItem[] => {
    const updatedOrderItems = items.map((item) => {
      item.precio = Number(
        priceListDetails.find((priceItem) => priceItem.productId === item.productId)?.precio ?? 0.0,
      );
      item.listaPrecioId = entity.listaPrecioId;
      return item;
    });

    return updatedOrderItems;
  };

  /**
   * Resets the prices of all items in the order to the original price or null, and also resets the priceListId to null.
   * This is used when the user wants to stop using a price list and instead use the original price of the items.
   */
  const resetItemsPrices = () => {
    const updatedOrderItems = orderItems.map((item) => {
      item.precio = item.originalPrice ?? null;
      item.listaPrecioId = null; // reset the price list too
      return item;
    });
    setOrderItems(updatedOrderItems);
  };

  const updateTotal = () => {
    let localTotal = 0.0;

    orderItems.forEach((item) => {
      localTotal += (item.precio ?? 0.0) * (item.cantidad ?? 0);
    });

    setTotalAmount(localTotal);
  };

  const computeAiInfo = () => {
    // Function to compute color and text
    if (aiExecutionResultOrder === null) {
      return;
    }

    if (aiExecutionResultOrder.client.matchBy === 'none') {
      setClientAiIconColor('red');
      setClientAiIconText('No se ha detectado cliente');
    } else if (aiExecutionResultOrder.client.matchBy === 'missing') {
      setClientAiIconColor('orange');
      setClientAiIconText('El cliente interpretado no se encontró en la base de datos');
    } else if (aiExecutionResultOrder?.client.id === client?.id) {
      setClientAiIconColor('green');
      setClientAiIconText('Cliente interpretado por IA');
    } else {
      setClientAiIconColor('cornflowerblue');
      setClientAiIconText('Cliente modificado por el usuario');
    }

    if (aiExecutionResultOrder?.deliveryDate == null) {
      setDeliveryDateAiIconColor('red');
      setDeliveryDateAiIconText('No se ha detectado fecha de entrega');
    } else if (aiExecutionResultOrder?.deliveryDate.split('T')[0] === deliveryDate!.split('T')[0]) {
      setDeliveryDateAiIconColor('green');
      setDeliveryDateAiIconText('Fecha de entrega interpretada por IA');
    } else {
      setDeliveryDateAiIconColor('cornflowerblue');
      setDeliveryDateAiIconText('Fecha de entrega modificada por el usuario');
    }

    if (aiExecutionResultOrder.deliveryPoint.matchBy === 'missing') {
      setDeliveryPointAiIconColor('orange');
      setDeliveryPointAiIconText('No se ha detectado punto de entrega');
    } else if (aiExecutionResultOrder.deliveryPoint.matchBy === 'none') {
      setDeliveryPointAiIconColor('red');
      setDeliveryPointAiIconText(
        'El punto de entrega interpretado no se encontró en la base de datos',
      );
    } else if (aiExecutionResultOrder?.client.id === client?.id) {
      setDeliveryPointAiIconColor('green');
      setDeliveryPointAiIconText('Punto de entrega interpretado por IA');
    } else {
      setDeliveryPointAiIconColor('cornflowerblue');
      setDeliveryPointAiIconText('Punto de entrega modificado por el usuario');
    }
  };

  /* FETCHING INITIAL DATA */
  const onLoadForm = () => {
    // get working data asynchronously
    Promise.all([
      APIClient.get('/listas-precio'),
      APIClient.get('/vendedores'),
      APIClient.get('/formas-pago'),
    ])
      .then((responses: AxiosResponse[]) => {
        // adding default values to the lists
        const responseData = responses.map((r) => r.data.data);

        responseData[0].unshift({ id: '', descripcion: 'Seleccione lista de precio' });
        setPriceLists(responseData[0]);

        responseData[1].unshift({ id: '', nombre: 'Seleccione vendedor' });
        setAgents(responseData[1]);

        responseData[2].unshift({ id: '', description: 'Seleccione forma de pago' });
        setPaymentMethods(responseData[2]);
      })
      .catch((error) => {
        toastManager.add('Error cargando información del formulario.', {
          appearance: 'error',
        });
        console.error('Error cargando información del formulario.', error);
      });
  };

  const onRetrieveEntity = async () => {
    const localEntity = (await APIClient.get(`/orders/${id}`)).data.data;

    const localOrderItems: OrderItem[] = [...(localEntity.Items ?? [])];

    let localAiExecution: AIExecution | null = null;
    let localAiExecutionResultOrder: InterpretOrderResult | null = null;

    if (localEntity.aiExecutionId) {
      // request AI order
      localAiExecution = (await APIClient.get(`/ai/executions/${localEntity.aiExecutionId}`)).data
        .data;

      if (localAiExecution !== null) {
        // parse the AI result order
        localAiExecutionResultOrder = JSON.parse(localAiExecution.resultOrder);

        APIClient.get(`/conversations/by-ai-execution/${localEntity.aiExecutionId}`)
          .then((response) => {
            setConversation(response.data.data);
          })
          .catch((error) => {
            const axiosError = error as AxiosError;
            if (axiosError && axiosError.response?.status == 404) {
              console.warn('No conversation found.');
            } else {
              console.error('Error al recuperar conversación', error);
            }
            setConversation(undefined);
          });
      }

      setAiExecutionResultOrder(localAiExecutionResultOrder);
      setAiExecution(localAiExecution);
    }

    // prepare the items array to display
    // create a new item for each one in the order
    const newOrderItems = localOrderItems.map((orderItem) => {
      orderItem.originalPrice = orderItem.precio; // copy the original price, if exists
      if (orderItem.aiOrderItemIndex !== null && localAiExecutionResultOrder) {
        orderItem.aiData = localAiExecutionResultOrder.items[orderItem.aiOrderItemIndex];
      }
      orderItem.listaPrecioId = localEntity.listaPrecioId;
      orderItem.keyData = String(Math.random());
      return orderItem;
    });

    if (localEntity.Cliente?.id) {
      // request delivery points based on client
      const deliveryPointsData = (
        await APIClient.get(`/clientes/${localEntity.Cliente.id}/puntos-entrega`)
      ).data.data;

      setClient(localEntity.Cliente);
      setDeliveryPoints(deliveryPointsData);
    }

    if (
      !isAdding &&
      localEntity.estadoOrderCodigo.match(
        new RegExp(`${StatusCode.draft}|${StatusCode.deliveredPartial}|${StatusCode.pending}`),
      )
    ) {
      // enabling edit mode
      setIsEditable(true);
    }

    // Don't apply prices on loading, because the order might have prices before
    // if (localEntity.listaPrecioId) {
    //   onChangePriceList(localEntity.listaPrecioId);
    // }

    setDeliveryDate(String(localEntity.fechaEntrega));
    setOrderItems(newOrderItems);
    setEntity(localEntity);
    return localEntity;
  };

  const prepareToSave = (entityToSave: any) => {
    // removes data not stored in model

    const orderItemsProcessed = orderItems
      .map((orderItem) => {
        if (orderItem.id === null && orderItem.Product == null) {
          return null;
        }

        delete orderItem.aiData;
        delete orderItem.keyData;
        delete orderItem.Product;

        return orderItem;
      })
      .filter(Boolean); // removes NULL items

    entityToSave.Items = orderItemsProcessed;

    if (itemsToDelete.length > 0) {
      entityToSave.assocToDelete = {};
      entityToSave.assocToDelete.Items = [...itemsToDelete];
    }

    if (entityToSave?.deliveryPointId === '') {
      entityToSave.deliveryPointId = null;
    }

    if (entityToSave?.clienteId === '') {
      entityToSave.clienteId = null;
    }

    return entityToSave;
  };

  const onSaveEntity = async (entityToSave: any) => {
    let saveData: any;

    if (isAdding) {
      saveData = (await APIClient.post('/orders', entityToSave)).data.data;
    } else {
      saveData = (await APIClient.patch(`/orders/${id}`, entityToSave)).data.data;
    }

    toastManager.add(
      `Pedido ${saveData.id} guardado con éxito`,
      {
        appearance: 'success',
        autoDismiss: true,
      },
      () => navigate('/pedidos'),
    );
    return saveData;
  };

  /* EVENT HANDLERS */
  const handleSelectCustomClientChange = async (e: any) => {
    if (e.target.value === null) {
      return;
    }
    // get the client data and then the delivery points
    const clientData = (await APIClient.get(`/clientes/${e.target.value}`)).data.data;

    const deliveryPointsData = (await APIClient.get(`/clientes/${e.target.value}/puntos-entrega`))
      .data.data;

    setClient(clientData ?? null);
    setDeliveryPoints(deliveryPointsData ?? []);
  };

  const handleSelectCustomClientLoadOptions = async (query: string) => {
    const clientData = (
      await APIClient.get(`/clientes?filter[razonSocial][like]=${encodeURIComponent(`%${query}%`)}`)
    ).data.data;

    const optionsFormatted = clientData.map((data) => ({
      value: data.id,
      label: data.razonSocial,
    }));

    return optionsFormatted;
  };

  const handleDeliveryDateChange = (e: any) => {
    setDeliveryDate(e.target.value);
  };

  const handleDeliveryPointChange = (e: any) => {
    setDeliveryPointId(e.target.value);
  };

  const handlePurgeItemsClick = () => {
    const orderItemsCopy = [...orderItems];

    // Filter and map uninterpreted items
    const uninterpretedItemIds = orderItemsCopy
      .filter((item) => item.aiData?.matchBy === 'none' && !item.Product?.id)
      .map((item) => item.id)
      .filter((id): id is number => id !== null);

    // Filter local order items that are not in the delete list
    const updatedOrderItems = orderItemsCopy.filter(
      (item) => item.id && ![...itemsToDelete, ...uninterpretedItemIds].includes(item.id),
    );

    // Update the lists of items to delete and the order items
    setItemsToDelete([...itemsToDelete, ...uninterpretedItemIds]);
    setOrderItems(updatedOrderItems);
    setShowPurgeModal(false);
  };

  const handleItemChange = async (index: number, productId: number | null, quantity: number) => {
    const localOrderItems = [...orderItems];
    const localItemsToDelete = [...itemsToDelete];
    localOrderItems[index].productId = productId;

    if (productId === null) {
      // remove the item from state
      const itemToDelete = localOrderItems.splice(index, 1)[0];

      if (itemToDelete.id !== null) {
        // stores the id of the item to be removed if it's stored in db
        localItemsToDelete.push(itemToDelete.id);
        setItemsToDelete(localItemsToDelete);
      }

      setOrderItems(localOrderItems);
      return;
    }

    // search for repeated items
    const localOrderItemsFiltered = localOrderItems.filter(
      (orderItem) => orderItem.productId === productId,
    );

    if (localOrderItemsFiltered.length > 1) {
      // if item repeated, inform and remove the repeated
      toastManager.add('El producto ya se encuentra en la orden.', {
        appearance: 'warning',
        autoDismiss: true,
      });

      localOrderItems.splice(index, 1);
      setOrderItems(localOrderItems);
      return;
    }

    let item = localOrderItems[index];

    // updates Product if required
    if (productId != item.Product?.id) {
      const productData: Product = (await APIClient.get(`/products/${productId}`)).data.data;

      item.Product = productData;
      setIsItemsModified(true);
    }

    // updates quantity
    item.cantidad = quantity;

    // rebuild the items list and store it
    item = updatePrices([item])[0];
    localOrderItems[index] = item;
    setOrderItems(localOrderItems);
  };

  const handleItemCreate = () => {
    let isOrderItemEmpty = false;
    orderItems.forEach((orderItem) => {
      // if the item is not stored in db and has no productId
      if (orderItem.id === null && orderItem?.Product == null) {
        toastManager.add('Complete el ítem vacío antes de agregar uno nuevo.', {
          appearance: 'warning',
          autoDismiss: true,
        });
        isOrderItemEmpty = true;
        return;
      }
    });

    if (isOrderItemEmpty) {
      // return without creating new item
      return;
    }

    const localOrderItems = [...orderItems];
    localOrderItems.push(generateOrderItemTemplate());
    setOrderItems(localOrderItems);
  };

  const onChangePriceList = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!e.target.value) {
      // reset the prices to the original prices
      resetItemsPrices();
      return;
    }

    const localPriceListId = parseInt(e.target.value);

    const priceListData: PriceList = (await APIClient.get(`/listas-precio/${localPriceListId}`))
      .data.data;

    setSelectedPriceListId(localPriceListId);
    setPriceListDetails(priceListData?.detalles ?? []);
  };

  const generateOrderItemTemplate = (): OrderItem => {
    return {
      id: null,
      orderId: id,
      productId: null,
      posicion: 9999,
      cantidad: 1,
      precio: null,
      listaPrecioId: selectedPriceListId,
      entregado: 0,
      modificadoresPrecio: '',
      aiOrderItemIndex: null,
      keyData: String(Math.random()),
    };
  };

  const handleCopyTextClick = async (e: any) => {
    e.preventDefault();

    try {
      setIsLoadingCopyText(true);
      const resumeData = (await APIClient.get(`/orders/${id}/plain-text`)).data.data;
      navigator.clipboard.writeText(resumeData);
      toastManager.add('Pedido copiado al portapapeles.', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error: any) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      setIsLoadingCopyText(false);
    }
  };

  const generateOrderInfoMessage = () => {
    const created = new Date(entity.createdAt);
    const updated = new Date(entity.updatedAt);
    const creatorUserFullName =
      entity.UsuarioPerfil?.Usuario &&
      `${entity.UsuarioPerfil?.Usuario?.firstName} ${entity.UsuarioPerfil?.Usuario.lastName}`;

    const message = (
      <>
        Creado el <strong>{created.toLocaleString()}</strong>
        {creatorUserFullName && (
          <>
            {' por '}
            <strong>{creatorUserFullName}</strong>
          </>
        )}
        {' a través de '}
        <strong>{renderSourceChannel(entity.sourceChannel)}</strong>
      </>
    );

    if (updated.getTime() - created.getTime() < 3 * 60 * 1000) {
      return <>{message}.</>;
    }
    return (
      <>
        {message} y actualizado el <strong>{updated.toLocaleString()}</strong>.
      </>
    );
  };

  const handleCloseModalButtonClick = () => {
    setShowAiMessageModal(false);
  };

  const handleOpenModalButtonClick = () => {
    setShowAiMessageModal(true);
  };

  const renderAiMessageModal = () => (
    <Modal size="lg" show={showAiMessageModal} onHide={handleCloseModalButtonClick}>
      <Modal.Header closeButton>
        <Modal.Title>Mensaje interpretado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {conversation ? (
          <ConversationContainer conversation={conversation} />
        ) : aiExecution?.request ? (
          <textarea
            className="w-100"
            style={{ height: '25rem' }}
            value={aiExecution.request}
            readOnly
          />
        ) : (
          <p>No hay conversación.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModalButtonClick}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const handleCloseModalPurgeItems = () => {
    setShowPurgeModal(false);
  };

  const handleOpenModalPurgeItems = () => {
    setShowPurgeModal(true);
  };

  const renderPurgeItemsModal = () => (
    <Modal size="lg" show={showPurgeModal} onHide={handleCloseModalPurgeItems}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar ítems no identificados</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Está seguro de que desea eliminar estos ítems?</p>
        <p>
          Se eliminarán todos los ítems que no pudieron ser interpretados correctamente ( marcados
          con <FontAwesomeIcon icon={faMagic} color={'tomato'} fixedWidth /> ). Asegúrese de revisar
          los ítems afectados usando el botón ( <FontAwesomeIcon icon={faRobot} fixedWidth /> Info
          IA ) para ver el mensaje original.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => handlePurgeItemsClick()}>
          Eliminar
        </Button>
        <Button variant="secondary" onClick={handleCloseModalPurgeItems}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const hours = UIUtils.generateHours();

  return (
    <>
      {renderAiMessageModal()}
      {renderPurgeItemsModal()}
      <EntityEditForm
        onLoadForm={onLoadForm}
        onRetrieveEntity={onRetrieveEntity}
        onSaveEntity={onSaveEntity}
        addMode={isAdding}
        isEditable={isEditable}
        isModified={isItemsModified}
        prepareToSave={prepareToSave}>
        <Row>
          <Col md={8}>
            <h1 className="d-flex mt-3 me-2">
              {isAdding ? 'Pedido nuevo ' : 'Pedido '}
              {entity.codigo === null ? `#${id}` : entity.codigo && `${entity.codigo}`}
              {entity.estadoOrderCodigo && (
                <div className="ms-3">
                  {UIUtils.getSolicitudEstadoBadge(entity.estadoOrderCodigo)}
                </div>
              )}
            </h1>
            <p className="mb-3 text-muted">{generateOrderInfoMessage()}</p>
          </Col>
          <Col md={4} className="d-flex align-items-center justify-content-end">
            <ButtonGroup aria-label="Operaciones de Pedido">
              {/* Only render these 2 buttons if there is AI info */}
              {aiExecutionResultOrder && (
                <>
                  <Button
                    onClick={() => setisAiDataVisible(!isAiDataVisible)}
                    title="Información de IA">
                    <FontAwesomeIcon icon={faRobot} fixedWidth />
                  </Button>

                  <Button
                    className={!aiExecutionResultOrder ? 'd-none' : ''}
                    onClick={handleOpenModalButtonClick}
                    title="Ver conversación original">
                    <FontAwesomeIcon icon={faComments} fixedWidth />
                  </Button>
                </>
              )}
              <Button
                onClick={handleCopyTextClick}
                disabled={isLoadingCopyText}
                title="Copiar al portapapeles">
                {!isLoadingCopyText ? (
                  <FontAwesomeIcon icon={faCopy} fixedWidth title="Copiar al portapapeles" />
                ) : (
                  <Loading className="" />
                )}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        {/* FIRST ROW */}
        <Row>
          <Col md={6}>
            <Row>
              <FormSelectCustom
                id="clienteId"
                label="Cliente"
                disabled={!isEditable}
                onLoadOptions={handleSelectCustomClientLoadOptions}
                onChange={handleSelectCustomClientChange}
                selectedOption={{
                  value: client?.id ?? null,
                  label: client?.razonSocial ?? '-',
                }}
                aiStatusIconColor={clientAiIconColor ?? undefined}
                aiInterpretDescription={clientAiIconText ?? undefined}
              />
            </Row>
            <Row>
              <Fade in={isAiDataVisible}>
                <div className="text-muted mt-1">
                  <FontAwesomeIcon icon={faRobot} fixedWidth />
                  <span>
                    &nbsp;Se ha utilizado <strong>{aiExecutionResultOrder?.client.hint}</strong>{' '}
                    para interpretar al cliente.
                  </span>
                </div>
              </Fade>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col md={6}>
                <FormSelectField
                  id="vendedorId"
                  label="Ejecutivo comercial"
                  defaultValue={entity.vendedorId}
                  choices={agents}
                  choiceIdField="id"
                  choiceLabelField="nombre"
                  disabled={!isEditable}
                  required
                />
              </Col>
              <Col md={6}>
                <Row>
                  <FormInputField
                    id="fechaVencimiento"
                    label="Fecha de vencimiento"
                    type="date"
                    defaultValue={
                      entity.vencimiento ? moment.utc(entity.vencimiento).format('YYYY-MM-DD') : ''
                    }
                    min={moment().format('YYYY-MM-DD')}
                    disabled={!isEditable}
                  />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* SECOND ROW */}
        <Row style={{ marginTop: '1em' }}>
          <Col md={3}>
            <FormInputField
              id="purchaseOrder"
              label="Orden de compra"
              defaultValue={entity.purchaseOrder}
              disabled={!isEditable}
            />
          </Col>
          <Col md={3}>
            <FormSelectField
              id="listaPrecioId"
              label="Lista de precios"
              defaultValue={entity.listaPrecioId ?? null}
              choices={priceLists}
              choiceIdField="id"
              choiceLabelField="descripcion"
              onChange={onChangePriceList}
              disabled={!isEditable}
              required
            />
          </Col>
          <Col md={3}>
            <FormSelectField
              id="formaPagoId"
              label="Forma de pago"
              defaultValue={entity.formaPagoId}
              choices={paymentMethods}
              choiceIdField="id"
              choiceLabelField="description"
              disabled={!isEditable}
              required
            />
          </Col>
          <Col md={3}>
            <FormInputField
              id="facturaTipo"
              label="Tipo de factura"
              defaultValue={entity.facturaTipo}
              disabled={!isEditable}
            />
          </Col>
        </Row>
        {entity.transferencistaName && (
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label className="form-label">Transferencista</label>
                <p className="form-control-plaintext">{entity.transferencistaName}</p>
              </div>
            </Col>
          </Row>
        )}

        {/* FOURTH ROW - DELIVERY */}
        <h2 className="mt-2">Entrega</h2>
        <Row>
          <Col md={6}>
            <Row>
              <Col md={4}>
                <FormInputField
                  id="fechaEntrega"
                  type="date"
                  label="Fecha de entrega"
                  defaultValue={moment.utc(entity.fechaEntrega).format('YYYY-MM-DD')}
                  min={moment().format('YYYY-MM-DD')}
                  disabled={!isEditable}
                  required
                  aiStatusIconColor={deliveryDateAiIconColor ?? undefined}
                  aiInterpretDescription={deliveryDateAiIconText ?? undefined}
                  onChange={handleDeliveryDateChange}
                />
                <Fade in={isAiDataVisible}>
                  <div className="flex-vertical-center-start mt-1 text-muted">
                    <FontAwesomeIcon icon={faRobot} fixedWidth />
                    <span>{aiExecutionResultOrder?.deliveryDate}</span>
                  </div>
                </Fade>
              </Col>
              <Col md={4}>
                <FormSelectField
                  label="Desde"
                  id="horaEntregaDesde"
                  defaultValue={entity.horaEntregaDesde}
                  choices={hours}
                  choiceIdField="hour"
                  choiceLabelField="hour"
                  disabled={!isEditable}
                />
              </Col>
              <Col md={4}>
                <FormSelectField
                  label="Hasta"
                  id="horaEntregaHasta"
                  defaultValue={entity.horaEntregaHasta}
                  choices={hours}
                  choiceIdField="hour"
                  choiceLabelField="hour"
                  disabled={!isEditable}
                />
              </Col>
              {/* fecha de confirmación */}
            </Row>
          </Col>
          <Col md={6}>
            <FormSelectCustom
              id="puntoEntregaId"
              label="Punto de entrega"
              disabled={!isEditable}
              options={deliveryPoints.map((point) => ({
                value: point.id.toString(),
                label: generateDeliveryString(point) ?? '',
              }))}
              selectedOption={{
                value: entity?.PuntoEntrega?.id,
                label: generateDeliveryString(entity?.PuntoEntrega) ?? '',
              }}
              onChange={handleDeliveryPointChange}
              aiStatusIconColor={deliveryPointAiIconColor ?? undefined}
              aiInterpretDescription={deliveryPointAiIconText ?? undefined}
            />
          </Col>
        </Row>

        {/* FIFTH ROW - ITEMS */}
        <h2 className="mt-2">
          Items
          {` (${orderItems?.length ?? 0})`}
        </h2>
        <div className="" style={{ overflowX: 'scroll' }}>
          <Table hover>
            <thead>
              <tr>
                <th style={{ minWidth: '1rem' }}></th>
                <th style={{ minWidth: '6rem', maxWidth: '10rem' }}>Código</th>
                <th style={{ minWidth: '25rem', width: 'auto' }}>Descripción</th>
                <th style={{ minWidth: '8rem', maxWidth: '15rem' }} className="text-start">
                  Cantidad
                </th>
                <th style={{ minWidth: '7rem', maxWidth: '12rem' }} className="text-start">
                  Entregado
                </th>
                <th style={{ minWidth: '6rem', maxWidth: '10rem' }} className="text-end">
                  Precio U.
                </th>
                <th style={{ minWidth: '6rem', maxWidth: '10rem' }} className="text-end">
                  Total
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {orderItems &&
                orderItems.map((item, index) => (
                  <OrderItemTableRow
                    key={item.keyData}
                    index={index}
                    item={item}
                    disabled={!isEditable}
                    isAiDataVisible={isAiDataVisible}
                    onChange={handleItemChange}
                  />
                ))}
              <tr className="text-end">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Total</td>
                <td className="fw-bold">{formatAmount(totalAmount)}</td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </div>
        {isEditable && (
          <div className="d-flex">
            <Button
              variant="outline-primary"
              onClick={handleItemCreate}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              Agregar ítem
            </Button>

            <Button
              disabled={
                !orderItems.some((item) => item.aiData?.matchBy === 'none' && !item.Product?.id)
              }
              className="mx-1"
              variant="warning"
              onClick={handleOpenModalPurgeItems}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              Eliminar items no interpretados
            </Button>
          </div>
        )}
      </EntityEditForm>
    </>
  );
}

export default withToastManager(withRouter(OrderAIEdit));
